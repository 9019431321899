<template>
  <!-- Edit help support modal -->
  <b-modal
    id="modal-edit-referral-code"
    :visible="isEditReferralCodeModalShow"
    cancel-variant="outline-secondary"
    size="lg"
    centered
    :title="$t('referralCode.form.editHeader')"
    hide-footer
    @change="val => $emit('update:is-edit-referral-code-modal-show', val)"
  >
    <template #default="{ hide }">
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <b-form
          @submit.prevent="handleSubmit(onSubmit)"
          @reset.prevent="resetModal"
        >
          <b-row>
            <b-col md="12">
              <validation-provider #default="validationContext" name="notes">
                <b-form-group
                  :label="$t('referralCode.form.notes')"
                  label-for="notes"
                >
                  <b-form-input
                    id="notes"
                    v-model="data.notes"
                    type="text"
                    :state="getValidationState(validationContext)"
                    :placeholder="$t('referralCode.form.notesPlaceholder')"
                  />

                  <b-form-invalid-feedback>
                    {{ validationContext.errors[0] }}
                  </b-form-invalid-feedback>
                </b-form-group>
              </validation-provider>
            </b-col>
          </b-row>

          <div class="modal-footer">
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('common.cancel') }}
            </b-button>
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              type="submit"
              :disabled="isEditReferralCodeLoading"
            >
              <div v-if="isEditReferralCodeLoading" class="d-flex align-center">
                <b-spinner type="grow" small class="mr-1" />
                Loading...
              </div>
              <span v-else>{{ $t('common.save') }}</span>
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-modal>
</template>

<script>
import {
  BModal,
  VBModal,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormInvalidFeedback,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'
import { ref, watch } from '@vue/composition-api'

import useReferralCodeList from './useReferralCodeList'

export default {
  components: {
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BModal,
    BButton,
    BFormInvalidFeedback,
    BSpinner,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: ['isEditReferralCodeModalActive', 'editedData'],
    event: 'update:is-edit-referral-code-modal-show',
  },
  props: {
    isEditReferralCodeModalShow: {
      type: Boolean,
      required: true,
    },
    editedData: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      required,
    }
  },

  setup(props, { emit }) {
    const {
      editReferralCode,
      isEditReferralCodeLoading,
    } = useReferralCodeList()

    const initialState = {
      notes: '',
    }

    const data = ref(JSON.parse(JSON.stringify(initialState)))
    const resetData = () => {
      data.value = JSON.parse(JSON.stringify(initialState))
    }

    // Note: If modal is open then set the data for initial state, if modal is closed then reset the data
    watch(
      () => props.isEditReferralCodeModalShow,
      val => {
        if (val) {
          data.value.notes = props.editedData.notes
        }

        if (!val) {
          resetData()
        }
      },
    )

    const resetModal = () => {
      resetData()
      emit('update:is-edit-referral-code-modal-show', false)
    }

    const updateReferralCode = () => {
      editReferralCode(0, {
        id: props.editedData._id,
        data: data.value,
      }).then(() => {
        emit('refetch-data')
        emit('update:is-edit-referral-code-modal-show', false)
      })
    }

    const { refFormObserver, getValidationState } = formValidation(resetData)

    const onSubmit = () => {
      if (data.value.notes !== props.editedData.notes) {
        updateReferralCode().then(() => {
          resetModal()
        })
      } else {
        emit('update:is-edit-referral-code-modal-show', false)
      }
    }

    return {
      data,
      onSubmit,
      resetModal,
      isEditReferralCodeLoading,

      refFormObserver,
      getValidationState,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/quill.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
