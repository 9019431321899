import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchReferralCodes(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/admin/referral-code', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addReferralCode(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post('/admin/referral-code/create', payload)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editReferralCode(ctx, { id, data }) {
      return new Promise((resolve, reject) => {
        axios
          .put(`/admin/referral-code/update/${id}`, data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteReferralCode(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .delete(`/admin/referral-code/delete/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    exportReferralCodes() {
      return new Promise((resolve, reject) => {
        axios
          .get(`/admin/referral-code?is_export=true`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
