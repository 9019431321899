import { ref, watch, computed } from '@vue/composition-api'

import store from '@/store'
import { useAsyncState } from '@vueuse/core'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'
import swal from 'sweetalert2'

export default function useReferralCodeList() {
  // Use toast
  const toast = useToast()

  const refReferralCodeTable = ref(null)
  // Table Handlers
  const tableColumns = [
    { key: 'created_by', label: 'Type', sortable: true },
    { key: 'referral_code', label: 'Referral Code', sortable: true },
    { key: 'notes', label: 'Notes', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalReferralCodes = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refReferralCodeTable.value
      ? refReferralCodeTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalReferralCodes.value,
    }
  })

  const refetchData = () => {
    refReferralCodeTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery], () => {
    refetchData()
  })

  // Add Referral Code
  const {
    state: addReferralCodeState,
    isLoading: isAddReferralCodeLoading,
    execute: addReferralCode,
  } = useAsyncState(
    async args => {
      const formData = new FormData()
      formData.append('referral_code', args.data.referral_code)
      formData.append('notes', args.data.notes)

      const response = await store.dispatch(
        'master-referral-code/addReferralCode',
        formData,
      )

      if (response.data.code === 200 || response.data.code === true) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Success creating new referral code',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }

      return response
    },
    null,
    { immediate: false },
  )

  // Edit Referral Code
  const {
    state: editReferralCodeState,
    isLoading: isEditReferralCodeLoading,
    execute: editReferralCode,
  } = useAsyncState(
    async args => {
      const response = await store.dispatch(
        'master-referral-code/editReferralCode',
        {
          id: args.id,
          data: args.data,
        },
      )

      if (response.data.code === 200 || response.data.code === true) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Success updating referral code',
            icon: 'BellIcon',
            variant: 'success',
          },
        })
      } else {
        toast({
          component: ToastificationContent,
          props: {
            title: response.data.message,
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }

      return response
    },
    null,
    { immediate: false },
  )

  // Delete Referral Code
  const {
    state: deleteReferralCodeState,
    isLoading: isDeleteReferralCodeLoading,
    execute: deleteReferralCode,
  } = useAsyncState(
    async args => {
      await swal
        .fire({
          title: 'Are you sure?',
          text: 'You will not be able to recover this referral code!',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          cancelButtonText: 'No, keep it',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
        .then(result => {
          if (result.value) {
            store
              .dispatch('master-referral-code/deleteReferralCode', args._id)
              .then(response => {
                if (response.data.code === 200) {
                  swal.fire({
                    icon: 'success',
                    title: 'Deleted!',
                    text: 'Referral code has been deleted.',
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                  refetchData()
                } else {
                  swal.fire({
                    title: 'Error!',
                    text: response.data.message,
                    icon: 'error',
                    customClass: {
                      confirmButton: 'btn btn-success',
                    },
                  })
                }
              })
          } else if (result.dismiss === swal.DismissReason.cancel) {
            swal.fire({
              title: 'Cancelled',
              text: 'Referral code is safe :)',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })

      return response
    },
    null,
    { immediate: false },
  )

  // Fetch Referral Code
  const fetchReferralCodes = (ctx, callback) => {
    store
      .dispatch('master-referral-code/fetchReferralCodes', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const { codes, total_data: total } = response.data.data
        callback(codes)
        totalReferralCodes.value = total
      })
      .catch(() => {})
  }

  // Export Referral Code
  const exportReferralCodes = callback => {
    store
      .dispatch('master-referral-code/exportReferralCodes')
      .then(response => {
        const { data } = response.data
        callback(data)
      })
      .catch(() => {})
  }

  const exportData = ref([])
  const refButtonExport = ref(null)

  const handleExport = async () => {
    if (!exportData.value.length) {
      try {
        // Call the exportReferralCodes function to fetch the data
        const fetchedData = await new Promise((resolve, reject) => {
          exportReferralCodes(data => resolve(data))
        })

        // Wait for the data to be fetched
        exportData.value = fetchedData

        toast({
          component: ToastificationContent,
          props: {
            title: 'Success Load Export from database',
            icon: 'BellIcon',
          },
        })
      } catch (error) {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error Load Export from database',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      }
    } else {
      refButtonExport.value.$el.click()
      toast({
        component: ToastificationContent,
        props: {
          title: 'Success Export CSV',
          icon: 'BellIcon',
        },
      })
    }
  }

  return {
    // Table Handlers
    tableColumns,
    perPage,
    currentPage,
    totalReferralCodes,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refReferralCodeTable,

    // Refetch Referral Code
    refetchData,

    // Fetch Referral Code
    fetchReferralCodes,

    // Export Referral Code
    exportData,
    handleExport,
    exportReferralCodes,
    refButtonExport,

    // Add Referral Code
    addReferralCode,
    addReferralCodeState,
    isAddReferralCodeLoading,

    // Edit Referral Code
    editReferralCode,
    editReferralCodeState,
    isEditReferralCodeLoading,

    // Delete Referral Code
    deleteReferralCode,
    deleteReferralCodeState,
    isDeleteReferralCodeLoading,
  }
}
