<template>
  <div>
    <b-modal
      id="modal-add-new-referral-code"
      size="lg"
      centered
      cancel-variant="outline-secondary"
      hide-footer
      :title="$t('referralCode.form.header')"
      :visible.sync="isAddNewReferralCodeModalShow"
      @change="val => $emit('update:is-add-new-referral-code-modal-show', val)"
    >
      <template #default="{ hide }">
        <validation-observer #default="{handleSubmit}" ref="refFormObserver">
          <b-form
            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetModal"
          >
            <b-row>
              <b-col md="12">
                <validation-provider
                  #default="validationContext"
                  name="referral_code"
                >
                  <b-form-group
                    :label="$t('referralCode.form.referral_code')"
                    label-for="referral_code"
                  >
                    <b-form-input
                      id="referral_code"
                      v-model="referralCodeData.referral_code"
                      type="text"
                      :state="getValidationState(validationContext)"
                      :placeholder="
                        $t('referralCode.form.referralCodePlaceholder')
                      "
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col md="12">
                <validation-provider
                  #default="validationContext"
                  name="notes"
                  rules="required"
                >
                  <b-form-group
                    :label="$t('referralCode.form.notes')"
                    label-for="notes"
                  >
                    <b-form-input
                      id="notes"
                      v-model="referralCodeData.notes"
                      type="text"
                      :state="getValidationState(validationContext)"
                      :placeholder="$t('referralCode.form.notesPlaceholder')"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <div class="modal-footer">
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                type="button"
                variant="outline-secondary"
                @click="hide"
              >
                {{ $t('common.cancel') }}
              </b-button>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                type="submit"
                :disabled="isAddReferralCodeLoading"
              >
                <div
                  v-if="isAddReferralCodeLoading"
                  class="d-flex align-center"
                >
                  <b-spinner type="grow" small class="mr-1" />
                  Loading...
                </div>
                <span v-else>{{ $t('common.create') }}</span>
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </template>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
  VBModal,
  BFormInput,
  BFormGroup,
  BForm,
  BRow,
  BCol,
  BFormInvalidFeedback,
  BButton,
  BSpinner,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import formValidation from '@core/comp-functions/forms/form-validation'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { ref } from '@vue/composition-api'
import useReferralCodeList from './useReferralCodeList'

export default {
  components: {
    BModal,
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BSpinner,
    BFormInvalidFeedback,

    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  model: {
    prop: 'isAddNewReferralCodeModalShow',
    event: 'update:is-add-new-referral-code-modal-show',
  },
  props: {
    isAddNewReferralCodeModalShow: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      required,
    }
  },
  setup(props, { emit }) {
    const initialState = {
      referral_code: '',
      notes: '',
    }
    const referralCodeData = ref(JSON.parse(JSON.stringify(initialState)))
    const resetData = () => {
      referralCodeData.value = JSON.parse(JSON.stringify(initialState))
    }

    const { addReferralCode, isAddReferralCodeLoading } = useReferralCodeList()

    const resetModal = () => {
      resetForm()
      resetData()
      emit('update:is-add-new-help-support-category-modal-show', false)
    }

    const onSubmit = () => {
      addReferralCode(0, { data: referralCodeData.value }).then(() => {
        emit('refetch-data')
        resetModal()
      })
    }

    const { refFormObserver, getValidationState, resetForm } = formValidation(
      resetData,
    )

    return {
      referralCodeData,
      onSubmit,
      resetModal,
      isAddReferralCodeLoading,

      refFormObserver,
      getValidationState,
      resetForm,
    }
  },
}
</script>
